import { useGlobalConfig } from '../hooks/configContext';
import { Cloud$BusinessUnitType } from '../types/cloudEnums';

type Props = {
  includeUnits?: string[];
  excludeUnits?: string[];
  excludeAffiliates?: string[];
  excludedTypes?: Cloud$BusinessUnitType[];
  onlyCentral?: boolean; // Only show this for central units
  children: React.ReactNode;
};

const BusinessUnitWrapper = ({
  children,
  includeUnits,
  excludeUnits,
  excludeAffiliates,
  onlyCentral,
  excludedTypes,
}: Props) => {
  const { BU_CONFIG } = useGlobalConfig();

  if (Array.isArray(excludedTypes) && excludedTypes.includes(BU_CONFIG.type)) {
    return null;
  }

  if (
    onlyCentral &&
    !['OWN_AND_OPERATE', 'WHITE_LABEL'].includes(BU_CONFIG.type)
  ) {
    return null;
  }
  if (!!excludeUnits && excludeUnits.includes(BU_CONFIG.slug)) {
    return null;
  }
  if (!!includeUnits && !includeUnits.includes(BU_CONFIG.slug)) {
    return null;
  }

  const { matchedPartner } = BU_CONFIG;
  if (
    !!excludeAffiliates &&
    matchedPartner &&
    matchedPartner.type === 'AFFILIATE' &&
    excludeAffiliates.some((utm_source) =>
      matchedPartner.config.utm_sources.includes(utm_source),
    )
  ) {
    return null;
  }

  return <>{children}</>;
};

export default BusinessUnitWrapper;
